/* .popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

.popup-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  
}

.popup-left {
  flex: 1;
  padding-right: 20px;
  text-align: center;
}

.popup-left h2 {
  font-size: 24px;
  font-weight: bold;
}

.popup-left p {
  font-size: 18px;
  margin-bottom: 10px;
}



.popup-right {
  flex: 1;
}

.popup-right h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}

.popup-right p {
  font-size: 14px;
  margin-bottom: 10px;
}

form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.register-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

/* Media Queries for responsiveness */

/* For tablets */
@media (max-width: 768px) {
  .popup-container {
      flex-direction: column; /* Stack the columns */
      width: 90%;
      padding: 15px;
      max-height: 100vh; /* Max height is the viewport height */
      overflow-y: auto;  /* Vertical scrolling */
      padding: 20px;
      box-sizing: border-box;
  }
  .popup-left {
      padding-right: 0;
      margin-bottom: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .popup-container {
      width: 100%;
      padding: 10px;
      max-height: 100vh; /* Max height is the viewport height */
      overflow-y: auto;  /* Vertical scrolling */
      padding: 20px;
      box-sizing: border-box;
  }
  h4 {
      font-size:  18px !important;
  }

  form input {
      padding: 8px;
  }
  .register-button {
      padding: 10px;
  }
  .close-button {
      font-size: 18px;
      right: 40px;
      top:20px;
  }
}

.input-with-icon {
  position: relative;
  margin-bottom: 20px; 
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px; 
  transform: translateY(-50%);
  color: #0548EC;
  pointer-events: none; 
}

.input-with-icon input {
  padding-left: 40px; 
  color: #0548EC;
  font-size: 16px;
}

.errorMessage {
  color: red;
  font-size: 12px;
  /* margin: 5px;  */
  position: absolute;
}

.placeholderPop {
  font-size: 16px;
  color: #0548EC;
}

.modal-body p {
  font-size: 14px !important;
}

.image-container img {
  max-width: 100%;
  border-radius: 30px;
}

div#exampleModal {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.8);
}

.form-group:not(:last-child) {
  margin-bottom: 27px !important;
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
      width: 750px !important;
  }
}