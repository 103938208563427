/*--------------------------------------------------------------
4. General
----------------------------------------------------------------*/
.cs_gap_y_24 {
  gap: 24px 0;
}
.cs_gap_y_40 {
  gap: 40px 0;
}
.cs_gap_y_30 {
  gap: 30px 0;
}
.cs_gap_y_50 {
  gap: 50px 0;
}
.cs_row_gap_50 {
  gap: 50px 0;
  @media (max-width: 991px) {
    gap: 30px 0;
  }
}
.cs_pr_95 {
  padding-right: 95px;
  @media (max-width: 1700px) {
    padding-right: 45px;
  }
  @media (max-width: 1400px) {
    padding-right: 25px;
  }
  @media (max-width: 1199px) {
    padding-right: 0;
    padding-top: 10px;
  }
}
.cs_gap_40 {
  gap: 40px;
}
.cs_post_pr_136 {
  padding-right: 136px;
  @media (max-width: 1700px) {
    padding-right: 50px;
  }
  @media (max-width: 1199px) {
    padding-right: 0px;
  }
}
.cs_mt_minus_110 {
  margin-top: -110px;
  position: relative;
  z-index: 5;
  @media (max-width: 991px) {
    margin-top: 110px;
  }
}
.cs_fs_94 {
  font-size: 94px;
  line-height: 1.1em;
  @media (max-width: 1700px) {
    font-size: 72px;
  }
  @media (max-width: 1199px) {
    font-size: 60px;
  }
  @media (max-width: 400px) {
    font-size: 42px;
  }
}
.cs_fs_84 {
  font-size: 84px;
  line-height: 1.12em;
  @media (max-width: 1700px) {
    font-size: 68px;
  }
  @media (max-width: 767px) {
    font-size: 56px;
  }
  @media (max-width: 400px) {
    font-size: 48px;
  }
}
.cs_fs_72 {
  font-size: 72px;
  line-height: 1.14em;
  @media (max-width: 1700px) {
    font-size: 3rem;
  }
  @media (max-width: 991px) {
    font-size: 1.8rem;
  }
  @media (max-width: 575px) {
    font-size: 48px;
  }
  @media (max-width: 350px) {
    font-size: 30px;
  }
}
.cs_fs_48 {
  font-size: 48px;
  line-height: 1.04em;
  @media (max-width: 1700px) {
    font-size: 36px;
  }
  @media (max-width: 991px) {
    font-size: 26px;
  }
}
.cs_fs_40 {
  font-size: 40px;
  line-height: 1.25em;
  @media (max-width: 1700px) {
    font-size: 32px;
  }
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}
.cs_fs_32 {
  font-size: 32px;
  line-height: 1.31em;
  @media (max-width: 1700px) {
    font-size: 26px;
  }
  @media (max-width: 991px) {
    font-size: 24px;
  }
  @media (max-width: 350px) {
    font-size: 21px;
  }
}
.cs_fs_24 {
  font-size: 24px;
  line-height: 1.41em;
  @media (max-width: 1700px) {
    font-size: 22px;
  }
}
.cs_fs_20 {
  font-size: 20px;
  line-height: 1.5em;
  @media (max-width: 991px) {
    font-size: 16px;
  }
}
.cs_fs_18 {
  font-size: 18px;
  line-height: 1.5em;
}

.cs_img_filed {
  height: 100%;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.cs_light {
  font-weight: 300;
}

.cs_normal {
  font-weight: 400;
}

.cs_medium {
  font-weight: 500;
}

.cs_semibold {
  font-weight: 600;
}

.cs_bold {
  font-weight: 700;
}

.cs_extra_bold {
  font-weight: 800;
}

.cs_black {
  font-weight: 900;
}

.cs_radius_3 {
  border-radius: 3px;
}

.cs_radius_5 {
  border-radius: 5px;
}

.cs_radius_7 {
  border-radius: 7px;
}

.cs_radius_10 {
  border-radius: 10px;
}

.cs_radius_15 {
  border-radius: 15px;
}
.cs_radius_20 {
  border-radius: 20px;
}
.cs_radius_25 {
  border-radius: 25px;
}
.cs_radius_30 {
  border-radius: 30px;
}

.cs_mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs_m0 {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #bcbcbc;
  opacity: 1;
}

.cs_vertical_middle {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.cs_vertical_middle_in {
  flex: none;
  width: 100%;
}

.cs_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs_bg_filed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cs_heading_color {
  color: $primary;
}
.cs_body_color {
  color: $secondary;
}
.cs_accent_color {
  color: $accent;
}
.cs_white_color {
  color: #fff;
}
.cs_white_bg {
  background-color: $white;
}
.cs_accent_bg {
  background-color: $accent;
}
.cs_gray_bg_1 {
  background-color: rgba(210, 234, 239, 0.2);
}
.cs_half_bg,
.cs_half_bg_2 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 60%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
  }
  > * {
    position: relative;
    z-index: 2;
  }
}
.cs_half_bg_2 {
  &::before {
    height: 50%;
    top: initial;
    bottom: 0;
  }
}
@media (min-width: 1701px) {
  .container {
    max-width: 1650px;
  }
}

.cs_shadow_1 {
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
}
.cs_shadow_2 {
  box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
}
.cs_btn.cs_style_1 {
  border-radius: 24px;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  font-weight: 500;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 40px;
  outline: none;
  border: none;
  position: relative;
  background: transparent;
  @media (max-width: 991px) {
    padding: 12px 30px;
  }
  span {
    position: relative;
    z-index: 2;
  }
  i {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 15px;
    height: 11px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }
  img {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    filter: brightness(0) invert(1);
    &:first-child {
      left: -20px;
    }
    &:last-child {
      left: 0;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }
  &::before {
    background-image: linear-gradient(134deg, #307bc4 0%, #274760 100%);
  }
  &::after {
    background-image: linear-gradient(270deg, #307bc4 0%, #274760 100%);
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 1;
    }
    img {
      &:first-child {
        left: 0px;
      }
      &:last-child {
        left: 20px;
      }
    }
  }
}
.cs_text_btn {
  display: inline-flex;
  position: relative;
  padding-bottom: 5px;
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: currentColor;
    right: 0;
    bottom: 0;
    transition: all 0.4s ease;
  }
  &:hover {
    color: #fff;
    &::before {
      width: 60%;
    }
  }
}
.cs_text_btn_2 {
  display: inline-flex;
  align-items: center;
  gap: 14px;
  .cs_text_btn_text {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
    }
  }
  .cs_text_btn_icon {
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    .cs_text_btn_text {
      &::before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }
    }
    .cs_text_btn_icon {
      transform: scale(1.1);
    }
  }
}
.cs_input_label {
  margin-bottom: 12px;
  @media (max-width: 1700px) {
    margin-bottom: 10px;
  }
}
.cs_form_field,
.react-datepicker-wrapper input {
  display: block;
  width: 100%;
  border-radius: 30px;
  outline: none;
  transition: all 0.3s ease;
  border: 1px solid rgba($accent, 0.5);
  padding: 26px 30px;
  background-color: transparent;
  &:focus {
    border-color: $accent;
  }
  @media (max-width: 1700px) {
    padding: 16px 20px;
    border-radius: 15px;
  }
}
.cs_form_field_2 {
  width: 100%;
  display: block;
  border: 1px solid #636363;
  outline: none;
  border-radius: 6px;
  padding: 13px 20px;
  transition: all 0.3s ease;
  &:focus {
    border-color: $accent;
  }
}
textarea.cs_form_field {
  resize: none;
}
textarea::placeholder,
input::placeholder,
.cs_form_field::placeholder,
.cs_form_field_2::placeholder {
  color: $secondary;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder,
.cs_form_field:-ms-input-placeholder,
.cs_form_field_2:-ms-input-placeholder {
  color: $secondary;
}

textarea::-ms-input-placeholder,
input::-ms-input-placeholder,
.cs_form_field::-ms-input-placeholder,
.cs_form_field_2::-ms-input-placeholder {
  color: $secondary;
}
.cs_with_icon_input {
  position: relative;
  i {
    position: absolute;
    top: 30px;
    right: 30px;
    color: $accent;
    font-size: 20px;
    pointer-events: none;
    display: flex;
    @media (max-width: 1700px) {
      top: 20px;
      left: 20px;
    }
  }
}

.cs_random_features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px 110px;
  justify-content: center;
  @media (max-width: 1700px) {
    gap: 15px 60px;
  }
  @media (max-width: 1380px) {
    gap: 15px 24px;
  }
  @media (max-width: 1121px) {
    gap: 24px;
    align-items: initial;
  }
  .cs_random_features_col {
    padding-top: 55px;
    @media (max-width: 1700px) {
      padding-top: 45px;
    }
    @media (max-width: 1380px) {
      padding-top: 25px;
    }
    @media (max-width: 1121px) {
      padding-top: 0px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    &:nth-child(2) {
      padding-top: 0;
    }
  }
  @media (min-width: 992px) {
    .cs_iconbox.cs_style_13 {
      max-width: 390px;
    }
  }
}
.cs_feature.cs_style_1 {
  max-width: 420px;
  width: 100%;
  height: 100%;
  flex: none;
  padding: 70px 50px;
  @media (max-width: 1700px) {
    max-width: 390px;
    padding: 50px 30px;
  }
  @media (max-width: 1380px) {
    max-width: 350px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    padding: 30px 20px;
  }
  .cs_feature_icon {
    height: 45px;
    width: 45px;
    flex: none;
    padding: 9px;
    img {
      filter: brightness(0) invert(1);
    }
  }
  .cs_feature_title {
    gap: 15px 18px;
    margin-bottom: 35px;
    position: relative;
    @media (max-width: 1700px) {
      gap: 15px;
    }
    > * {
      position: relative;
      z-index: 2;
    }
    &::before {
      content: '';
      position: absolute;
      height: calc(100% + 42px);
      width: calc(100% + 160px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      border-radius: 30px;
      background-color: #86bbf1;
      transition: all 0.4s ease;
      @media (max-width: 1700px) {
        width: calc(100% + 100px);
      }
    }
  }
  &:hover {
    .cs_feature_title {
      &::before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}
.cs_tab {
  display: none;
  &.active {
    display: block;
  }
}

.cs_tabs.cs_style1 {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    gap: 45px;
    flex-direction: column-reverse;
  }
  .cs_tab_body {
    padding-left: 210px;
    position: relative;
    flex: none;
    width: 50%;
    padding-right: 7%;
    @media (max-width: 1700px) {
      padding-right: 0;
      padding-left: 160px;
    }
    @media (max-width: 991px) {
      width: 100%;
      padding-left: 75px;
    }
  }
  .cs_tab_links {
    list-style: none;
    margin: 0;
    padding: 0;
    border-right: 1px solid $accent;
    width: 50%;
    flex: none;
    @media (max-width: 991px) {
      width: 100%;
      display: flex;
      justify-content: center;
      border-top: 1px solid $accent;
      border-right: 0;
      .cs_testimonial_1_avatar_right {
        display: none;
      }
    }
    li {
      position: relative;
      @media (max-width: 991px) {
        padding-top: 30px;
      }
      &::before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        right: -5px;
        top: 50%;
        margin-top: -5px;
        background-color: $accent;
        transition: all 0.4s ease;
        @media (max-width: 991px) {
          right: initial;
          top: 0;
          left: 50%;
        }
      }
      &:not(:last-child) {
        margin-bottom: 35px;
        @media (max-width: 991px) {
          margin-bottom: 0;
        }
      }
      &:nth-child(even) {
        padding-left: 110px;
        @media (max-width: 991px) {
          padding-left: 0;
        }
      }
      &.active {
        &::before {
          transform: scale(1.8);
        }
        .cs_tab_link_in {
          box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
        }
      }
    }
    .cs_tab_link_in {
      position: relative;
      display: inline-flex;
      padding: 22px 50px;
      border-radius: 25px;
      transition: all 0.4s ease;
      @media (max-width: 1700px) {
        padding: 20px 35px;
        margin-right: 60px;
      }
      @media (max-width: 991px) {
        padding: 20px 35px;
        margin-right: 0px;
      }
      @media (max-width: 575px) {
        padding: 15px;
        border-radius: 15px;
      }
      > a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }
    }
    .cs_testimonial_1_avatar {
      display: inline-flex;
      align-items: center;
      gap: 20px 40px;
      @media (max-width: 1700px) {
        gap: 20px;
      }
      img {
        border-radius: 50%;
        height: 126px;
        width: 126px;
        object-fit: cover;
        @media (max-width: 1700px) {
          height: 80px;
          width: 80px;
        }
        @media (max-width: 575px) {
          height: 60px;
          width: 60px;
        }
      }
      p {
        line-height: 1.6em;
      }
    }
  }
}

.cs_rating {
  width: 99px;
  position: relative;
  font-size: 16px;
  .cs_rating_bg {
    display: flex;
    gap: 2px;
  }
  .cs_rating_percentage {
    display: flex;
    gap: 2px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
  }
}

.cs_radio_wrap {
  position: relative;
  .cs_radio_input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked {
      + .cs_radio_label {
        &::after {
          transform: scale(1);
        }
      }
    }
  }
  .cs_radio_label {
    display: block;
    padding-left: 32px;
    &::before {
      content: '';
      height: 20px;
      width: 20px;
      border: 1px solid $accent;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0;
    }
    &::after {
      content: '';
      height: 14px;
      width: 14px;
      background-color: $accent;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 3px;
      transition: all 0.3s ease;
      transform: scale(0);
    }
  }
}
.cs_radio_group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 42px;
  @media (max-width: 1700px) {
    gap: 10px 30px;
  }
}
.cs_shape_wrap {
  position: relative;
  .cs_shape_1 {
    width: 1884px;
    height: 1884px;
    border-radius: 50%;
    opacity: 0.6;
    background: radial-gradient(
      42.35% 42.35% at 50% 50%,
      rgba(134, 187, 241, 0.38) 0%,
      rgba(134, 187, 241, 0) 100%
    );
    position: absolute;
    top: 31%;
    right: -37%;
    z-index: -1;
    &.cs_position_1 {
      right: initial;
      left: -38%;
      top: -59%;
    }
    &.cs_position_2 {
      right: 38%;
      top: 39%;
    }
    &.cs_position_3 {
      right: 42%;
      top: 22%;
    }
    &.cs_position_4 {
      right: -35%;
      top: initial;
      bottom: -10%;
    }
    &.cs_position_5 {
      right: -30%;
      top: -8%;
    }
  }
  .cs_shape_2 {
    position: absolute;
    left: 52%;
    top: 13%;
    z-index: -1;
  }
  .cs_shape_3 {
    position: absolute;
    right: 0;
    top: -41%;
    z-index: -1;
    @media (max-width: 1700px) {
      right: -60px !important;
    }
  }
 
}
.cs_appointment_section_1 {
  .container {
    position: relative;
  }
  .cs_appointment_img {
    position: absolute;
    bottom: 0;
    left: -9.5%;
    width: 55%;
    @media (max-width: 1400px) {
      left: -16%;
      width: 63%;
    }
    @media (max-width: 1199px) {
      left: -24%;
      width: 72%;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.cs_iconbox_carousel_1 {
  .cs_slider_activate {
    width: 65.5vw;
    padding-left: 55px;
    padding-right: 55px;
    @media (max-width: 1700px) {
      width: 64vw;
    }
    @media (max-width: 1500px) {
      width: 66vw;
    }
    @media (max-width: 991px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (max-width: 480px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      height: 60%;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 20px 0px 0px 20px;
      background: linear-gradient(154deg, #d2eaef 0%, #86bbf1 100%);
      @media (max-width: 991px) {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
      }
    }
  }
  // .slick-slider {
  //   width: 64vw;
  //   @media (max-width: 991px) {
  //     width: 88vw;
  //   }
  // }
  .slick-list {
    position: relative;
    z-index: 2;
    padding-bottom: 40px;
    padding-top: 80px;
    margin-bottom: -40px;
    // overflow: initial;
    @media (max-width: 991px) {
      padding-top: 40px;
    }
    @media (max-width: 480px) {
      padding-top: 20px;
    }
  }

  // .slick-slide {
  //   opacity: 0;
  //   transition: all 0.3s ease;
  //   &.slick-active {
  //     opacity: 1;
  //   }
  // }
}
.cs_pagination_box {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  @media (max-width: 991px) {
    gap: 15px;
  }
  .cs_pagination_item {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(21, 32, 70, 0.07);
    height: 45px;
    width: 45px;
    color: $primary;
    &.active {
      background-color: $accent;
      color: #fff;
      pointer-events: none;
    }
  }
  a.cs_pagination_item:hover {
    background-color: $accent;
    color: #fff;
  }
  .cs_pagination_arrow {
    transition: all 0.4s ease;
    img {
      width: 16px;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}

.cs_appointment_form.cs_style_1 {
  background-color: #fff;
  box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
  padding: 60px 90px;
  @media (max-width: 1700px) {
    padding: 60px 50px;
  }
  @media (max-width: 575px) {
    padding: 50px 25px;
  }
  .cs_appointment_categories {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 70px;
    @media (max-width: 1700px) {
      gap: 15px 30px;
    }
  }
  .cs_appointment_form_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    gap: 25px;
    flex-wrap: wrap;
    @media (max-width: 1400px) {
      margin-bottom: 40px;
      > h2 {
        width: 100%;
      }
    }
    @media (max-width: 991px) {
      margin-bottom: 30px;
      gap: 20px;
      > * {
        width: 100%;
      }
    }
  }
  .cs_appointment_form_icon {
    flex: none;
    height: 70px;
    width: 70px;
    padding: 18px;
  }
  label {
    display: block;
    color: $primary;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: -5px;
  }
  input,
  textarea {
    border: 0;
    border-bottom: 1px solid $ternary;
    display: block;
    width: 100%;
    padding: 0;
    outline: none;
    transition: all 0.3s ease;
    &:focus {
      border-color: $accent;
    }
  }
  .cs_appointment_form_field {
    display: flex;
    align-items: center;
    gap: 25px;
    width: 300px;
    flex: none;
    @media (max-width: 991px) {
      gap: 15px;
      width: 280px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .cs_appointment_form_field_right {
    flex: 1;
  }
  .cs_appointment_form_fields {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 100px;
    justify-content: space-between;
    @media (max-width: 1400px) {
      gap: 40px 30px;
    }
    @media (max-width: 991px) {
      gap: 25px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .cs_appointment_category {
    position: relative;
    span {
      display: inline-block;
      position: relative;
      transition: all 0.3s ease;
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 0%;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $accent;
        transition: all 0.3s ease;
      }
    }
    input {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      &:checked {
        + span {
          color: $primary;
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  &.cs_type_1 {
    border-radius: 25px 25px 0px 0px;
    border: 1px solid #fff;
    border-bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(21.5px);
    max-width: 670px;
    width: 100%;
    padding: 45px 50px;
    position: relative;
    z-index: 2;
    @media (max-width: 991px) {
      padding: 30px 25px;
    }
    .cs_down_btn {
      height: 60px;
      width: 60px;
      position: absolute;
      right: -30px;
      top: -30px;
      background-color: #fff;
      color: $accent;
      &:hover {
        transform: rotate(-20deg);
      }
      @media (max-width: 575px) {
        right: 5px;
        top: 5px;
        transform: scale(0.6);
        &:hover {
          transform: scale(0.6) rotate(-20deg);
        }
      }
    }
    label {
      color: #fff;
    }
    .cs_appointment_form_field {
      width: 100%;
      margin-bottom: 35px;
      @media (max-width: 991px) {
        margin-bottom: 25px;
      }
      input,
      textarea {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.5);
        color: #fff;
        padding-bottom: 4px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
        &::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
        &:focus {
          border-color: #fff;
        }
      }
    }
  }
}
.cs_page_heading {
  background: #fff;
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
}
.cs_page_heading_in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 25px 0;
}
.breadcrumb {
  margin: 0;
  font-size: 20px;
}
.breadcrumb-item.active {
  color: $ternary;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: $secondary;
}
.cs_search_form {
  max-width: 360px;
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border: 1px solid $accent;
    height: 50px;
    border-radius: 20px;
    padding: 5px 20px;
    outline: none;
  }
  .cs_search_btn {
    position: absolute;
  }
  .cs_search_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #274760;
    &:hover {
      color: $accent;
    }
  }
}
.cs_related_doctor_title {
  position: absolute;
  z-index: 1;
  left: 50%;
  width: 50%;
  top: 80px;
  margin-bottom: 0;
  @media (max-width: 1700px) {
    top: 50px;
    padding-left: 80px;
  }
  @media (max-width: 1199px) {
    top: 30px;
    padding-left: 50px;
  }
  @media (max-width: 991px) {
    position: initial;
    padding-left: 0px;
    padding-bottom: 50px;
  }
}
.cs_doctor_profile.cs_style_1 {
  display: flex;
  align-items: flex-end;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  .cs_doctor_info {
    margin-left: -180px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    @media (max-width: 991px) {
      margin-left: 0px;
    }
  }

  .cs_doctor_profile_right {
    flex: 1;
    padding: 180px 85px 100px 0px;
    @media (max-width: 1700px) {
      padding: 150px 85px 70px 0px;
    }
    @media (max-width: 1400px) {
      padding: 150px 0px 70px 0px;
    }
    @media (max-width: 1199px) {
      padding: 150px 0px 0px 0px;
    }
    @media (max-width: 991px) {
      padding: 25px 0px 0px 0px;
      width: 100%;
    }
  }
  .cs_social_links a {
    height: 30px;
    width: 30px;
    background-color: $accent;
    color: #fff;
    font-size: 14px;
  }
  .cs_doctor_info_in {
    padding: 50px 95px 46px;
    @media (max-width: 1700px) {
      padding: 40px 50px 40px;
    }
    @media (max-width: 767px) {
      padding: 30px;
    }
    @media (max-width: 480px) {
      br {
        display: none;
      }
    }
  }
  .cs_doctor_meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 37px 95px;
    @media (max-width: 1700px) {
      padding: 30px 50px;
    }
    @media (max-width: 1400px) {
      padding: 20px 50px;
    }
    @media (max-width: 767px) {
      padding: 20px 30px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      gap: 6px 0;
      padding: 20px 15px;
    }
  }
  .cs_doctor_meta_left {
    display: flex;
    align-items: center;
    gap: 20px 70px;
    @media (max-width: 1700px) {
      gap: 15px 40px;
    }
    @media (max-width: 575px) {
      gap: 15px 20px;
    }
    a {
      display: flex;
      align-items: center;
      gap: 15px;
      @media (max-width: 991px) {
        gap: 10px;
      }
      &:hover {
        color: rgba($white, 0.5);
      }
    }
  }
  .cs_doctor_meta_right {
    a {
      display: flex;
      gap: 11px;
      align-items: center;
      span {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: #fff;
          bottom: 3px;
          right: 0;
          transition: all 0.3s ease;
        }
      }
      &:hover {
        color: $white;
        span {
          &::before {
            width: 60%;
          }
        }
      }
    }
  }
  .cs_doctor_img {
    max-width: 45%;
    @media (max-width: 1700px) {
      max-width: 50%;
    }
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
}
.cs_single_doctor {
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
  h3 {
    padding: 23px 15px;
    margin-top: -35px;
    position: relative;
    z-index: 1;
  }
}
.cs_list.cs_style_1,
.cs_list.cs_style_2,
.cs_list.cs_style_3 {
  .cs_list_title {
    padding-left: 50px;
    position: relative;
    margin-bottom: 25px;
    svg,
    img {
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}
.cs_list.cs_style_1 {
  p + p {
    margin-top: 4px;
  }
  ul {
    list-style: none;
    padding-left: 50px;
    margin: 0;
    li {
      position: relative;
      padding-left: 28px;
      &::before {
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $accent;
        position: absolute;
        left: 0;
        top: 8px;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
.cs_list.cs_style_2 {
  ul {
    li {
      position: relative;
      padding-left: 22px;
      svg,
      img {
        position: absolute;
        left: 0;
        top: 8px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
.cs_list.cs_style_3 {
  ul {
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    padding: 35px 45px;
    max-width: 454px;
    li {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      span {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 120px;
        i {
          color: $accent;
        }
      }
    }
  }
}
.cs_doctor_details {
  position: relative;
  .cs_doctor_details_bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 490px;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media (max-width: 1700px) {
      height: 420px;
    }
    @media (max-width: 1199px) {
      height: 467px;
    }
    @media (max-width: 575px) {
      height: 280px;
    }
  }
  .cs_social_links {
    padding: 23px 40px;
    display: inline-flex;
    position: absolute;
    top: 368px;
    right: 12px;
    z-index: 2;
    @media (max-width: 1700px) {
      top: 298px;
    }
    @media (max-width: 1199px) {
      top: 342px;
    }
    @media (max-width: 991px) {
      position: initial;
      margin-top: 35px;
      padding: 15px 30px;
    }
    a {
      height: 30px;
      width: 30px;
      background-color: #fff;
      color: $accent;
      font-size: 16px;
    }
  }
}
.cs_contact_info.cs_style_1 {
  li {
    &:not(:last-child) {
      margin-bottom: 42px;
      @media (max-width: 1700px) {
        margin-bottom: 25px;
      }
      @media (max-width: 991px) {
        margin-bottom: 25px;
      }
    }
  }
}
.cs_timetable {
  height: 1950px;
  padding-left: 110px;
  position: relative;
  padding-right: 10px;
  margin-top: 15px;
  &::before,
  &::after {
    content: '';
    height: calc(100% - 40px);
    width: 10px;
    background-color: #fff;
    position: absolute;
    top: 20px;
    z-index: 2;
  }
  &::before {
    left: 100px;
  }
  &::after {
    left: 100%;
    width: 15vw;
    top: 0;
    height: 100%;
  }
  @media (max-width: 1199px) {
    width: 1230px;
  }
}

.cs_timetable_vertical {
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100vw;
      left: 100px;
      top: 12px;
      background-color: rgba($accent, 0.5);
    }
    &:first-child,
    &:last-child {
      &::before {
        background-color: $accent;
      }
    }
  }
}

.cs_timetable_in {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid $accent;
  border-top: none;
  border-bottom: none;
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 12px;
    width: calc(100% - 130px);
    right: 14px;
    background-color: #fff;
    z-index: 1;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
    height: 13px;
  }
  > div {
    flex: 1;
    border-right: 1px solid rgba($accent, 0.5);
    position: relative;
    padding: 12px 0;
    &:last-child {
      border-right: 0;
    }
    > div {
      padding: 8px;
    }
    .cs_timetable_date {
      position: absolute;
      top: -20px;
      z-index: 1;
      padding: 0 !important;
      width: 100%;
      text-align: center;
    }
  }
}
@for $i from 1 through 13 {
  .cs_hour_#{$i} {
    height: (148 * $i) + px !important;
  }
}
.cs_table_doctor {
  border-radius: 10px;
  border: 1.5px solid $accent;
  background-color: #d2eaef;
  height: 100%;
  padding: 28px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  p {
    margin-bottom: 0;
    &:not(:last-child) {
      border-bottom: 1px solid $accent;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1700px) {
    padding: 15px 12px;
  }
}
.cs_contact_form.cs_style_1 {
  padding: 83px 21.5%;
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
  @media (max-width: 991px) {
    padding: 50px;
  }
  @media (max-width: 400px) {
    padding: 50px 25px;
  }
}

.cs_map {
  height: 650px;
  @media (max-width: 1700px) {
    height: 550px;
  }
  @media (max-width: 1400px) {
    height: 450px;
  }
  @media (max-width: 575px) {
    height: 350px;
  }
  iframe {
    height: 100%;
    width: 100%;
    border: none;
    filter: grayscale(100%);
    border-radius: 30px;
  }
}
.cs_blog_details_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px 30px;
  .cs_social_links {
    a {
      height: 42px;
      width: 42px;
      border: 1px solid $accent;
      color: $accent;
      font-size: 18px;
      &:hover {
        background-color: $accent;
        color: #fff;
        transform: initial;
      }
    }
  }
}
.cs_blog_details_info_left {
  display: flex;
  align-items: center;
  gap: 20px 40px;
  @media (max-width: 1199px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.cs_blog_details_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: 991px) {
    gap: 12px;
  }
  a {
    padding: 11px 24px;
    border: 1px solid $accent;
    border-radius: 20px;
    color: $primary;
    @media (max-width: 991px) {
      padding: 7px 15px;
      border-radius: 15px;
    }
    &:hover {
      background-color: $accent;
      color: #fff;
    }
  }
}
.cs_blog_details {
  font-size: 18px;
  line-height: 1.55em;
  color: $primary;
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.31em;
    @media (max-width: 991px) {
      font-size: 28px;
    }
  }
  p {
    margin-bottom: 34px;
  }
  ol {
    margin-bottom: 60px;
    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  blockquote {
    min-height: 645px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    margin: 75px 0;
    padding: 100px 146px 0 0;
    display: flex;
    align-items: flex-end;
    font-size: 32px;
    line-height: 1.31em;
    color: #fff;
    font-weight: 600;
    font-style: normal;
    @media (max-width: 1400px) {
      padding: 100px 100px 0 0;
    }
    @media (max-width: 991px) {
      font-size: 24px;
    }
    @media (max-width: 575px) {
      padding: 50px 30px 0 0;
      min-height: 450px;
    }
    @media (max-width: 400px) {
      padding-right: 0;
    }
    p {
      margin: 0;
      width: 100%;
      background-color: $accent;
      padding: 40px 70px 40px 120px;
      position: relative;
      border-radius: 0 20px 0 0;
      @media (max-width: 1400px) {
        padding: 40px 40px 40px 120px;
      }
      @media (max-width: 575px) {
        padding: 40px 30px 40px 80px;
      }
      &::before {
        content: '“';
        position: absolute;
        left: 48px;
        top: 55px;
        font-size: 80px;
        color: rgba($white, 0.3);
        @media (max-width: 575px) {
          left: 25px;
        }
        @media (max-width: 400px) {
          display: none;
        }
      }
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.41em;
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 55px 0 86px 0;
    li {
      &:not(:last-child) {
        margin-bottom: 55px;
      }
    }
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.cs_author {
  display: flex;
  gap: 44px;
  padding: 58px 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  @media (max-width: 1700px) {
    gap: 30px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 20px;
  }
  .cs_author_img {
    flex: none;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6em;
    margin-bottom: 15px;
    @media (max-width: 575px) {
      margin-bottom: 5px;
    }
  }
}
.form-check {
  padding-left: 38px;
  margin-bottom: 0;
  color: $primary;
  font-size: 18px;
  line-height: 1.5em;
  .form-check-input {
    margin-left: -38px;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    &:checked {
      background-color: $accent;
      border-color: $accent;
    }
  }
}

.cs_newsletter.cs_style_1 {
  .cs_newsletter_title {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 11px;
    @media (max-width: 1700px) {
      font-size: 34px;
    }
    @media (max-width: 991px) {
      font-size: 30px;
    }
  }
  .cs_newsletter_form {
    position: relative;
    margin-top: 25px;
  }
  // .cs_newsletter_input {
  //   width: 100%;
  //   border-radius: 2.6em;
  //   border: 1px solid rgb($accent, 0.5);
  //   background: transparent;
  //   display: block;
  //   height: 80px;
  //   padding: 15px 185px 15px 32px;
  //   outline: none;
  //   transition: all 0.3s ease;
  //   @media (max-width: 1700px) {
  //     height: 70px;
  //     padding: 10px 175px 10px 32px;
  //   }
  //   &:focus {
  //     border-color: $accent;
  //   }
  // }
  .cs_btn.cs_style_1 {
    position: absolute;
    right: 15px;
    top: 15px;
    @media (max-width: 1700px) {
      top: 10px;
      right: 10px;
    }
  }
}

.cs_newsletter.cs_style_5 {
  text-align: center;
  .cs_newsletter_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 30px;
    @media (max-width: 1700px) {
      font-size: 24px;
    }
  }
  .cs_form_field {
    border-color: #636363;
    font-size: 20px;
    line-height: 1.5em;
    padding: 19px 25px;
    border-radius: 7px;
    margin-bottom: 28px;
    @media (max-width: 1700px) {
      font-size: 18px;
      padding: 13px 20px;
      margin-bottom: 20px;
    }
  }
}

/* Start Comment */
.comments-area {
  background-color: #f3f5f6;
  border-radius: 10px;
  padding: 40px 50px;
  @media (max-width: 1199px) {
    padding: 35px 25px;
  }
}
.comment-list {
  list-style: none;
  padding: 0;
  margin: 0;
  > .comment {
    padding: 0px 15px;
    margin-bottom: 45px;
    @media (max-width: 575px) {
      padding: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .children {
    margin-top: 45px;
    .comment {
      &:not(:first-child) {
        border-width: 1px 0 0;
        border-style: solid;
        margin-top: 30px;
        padding-top: 30px;
      }
    }
  }
  .bypostauthor {
    &:not(:last-child) {
      border-bottom: 1px solid $border;
      margin-bottom: 50px;
    }
  }
  .comment-body {
    padding-left: 90px;
    padding-top: 0;
    position: relative;
    padding-right: 150px;
    @media (max-width: 1199px) {
      padding-right: 120px;
    }
    @media (max-width: 575px) {
      padding-right: 0;
      padding-bottom: 40px;
      padding-left: 70px;
    }
  }
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .avatar {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
  .comment-author {
    .says {
      display: none;
    }
  }
  .fn {
    font-style: initial;
    font-weight: 500;
    color: $primary;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 0px;
    line-height: 1.2em;
  }
  .comment-meta {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 5px;
    color: $ternary;
  }
  .comment-meta {
    a {
      display: inline-flex;
      position: relative;
      align-items: center;
      gap: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
  .comment-meta + p,
  .comment-meta + p + p {
    font-size: 18px;
    line-height: 1.55em;
    margin-bottom: 0;
    margin-left: -90px;
    @media (max-width: 1700px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      margin-left: -70px;
    }
  }
  .reply {
    line-height: 1.6em;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    bottom: 2px;
    right: 0;
    color: #636363;
    padding-left: 40px;
    @media (max-width: 1700px) {
      font-size: 16px;
    }
    a {
      display: inline-block;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: currentColor;
        right: 0;
        bottom: 2px;
        transition: all 0.4s ease;
      }
      &:hover {
        &::before {
          width: 60%;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 27px;
      height: 22px;
      background-image: url(../../../public/icons/reply.svg);
      left: 0;
      background-size: contain;
      background-position: center;
      top: 2px;
    }
  }
  .bypostauthor {
    .comment-body:last-child {
      margin-bottom: 0;
    }
  }
}

.url {
  font-weight: 600;
  font-size: 20px;
  color: $primary;
  display: inline-block;
  line-height: 1.5em;
  @media (max-width: 1700px) {
    font-size: 18px;
  }
}

#commentform p.logged-in-as {
  font-size: 16px;
  margin-top: -5px;
}

#commentform p.logged-in-as a:hover {
  text-decoration: underline;
}

.comment-reply-title small {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
  color: red;
}

.comment-reply-title small a:hover {
  text-decoration: underline;
}
/* End Comment */

/* Start Time Picker */
.ui-timepicker-standard {
  border: 1px solid $accent;
  border-radius: 20px;
  padding: 10px;
  margin-top: 5px;
  color: $primary;
  font-family: inherit;
  .ui-state-hover {
    color: $primary;
    border-color: rgba($accent, 0.3);
    background-color: rgba($accent, 0.15);
    border-radius: 10px;
    color: $accent;
  }
  a {
    cursor: pointer;
    font-size: 16px;
    line-height: 1.2em;
    padding: 9px 15px;
  }
  .ui-timepicker-viewport {
    padding-right: 0 !important;
  }
  .ui-widget.ui-widget-content {
    border: none;
  }
}
.ui-widget-content a {
  color: $primary;
}

/* End Time Picker */
/* Start Date Picker */
.ui-datepicker.ui-widget.ui-widget-content {
  border-color: $accent;
  border-radius: 20px;
  margin: 5px 0;
  color: $primary;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: none;
  color: $primary;
  background: transparent;
  text-align: center;
}
.ui-widget {
  font-family: inherit;
}
.ui-datepicker table {
  font-size: 16px;
  margin: 0 0 4px;
}
.ui-widget-header {
  background: transparent;
  border: none;
}
.ui-datepicker {
  width: 388px;
  padding: 5px;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  color: $accent;
  font-weight: 500;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  color: $accent;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: rgba($accent, 0.15);
  color: $accent;
  border: 1px solid rgba($accent, 0.3);
  border-radius: 7px;
  font-weight: 500;
}
.ui-widget-header {
  color: $primary;
  font-weight: 600;
  font-weight: 16px;
}
.ui-datepicker th {
  color: $primary;
  font-weight: 500;
  font-size: 16px;
}
/* End Date Picker */
/* Start Select Two */
.select2-container {
  width: 100% !important;
  .select2-selection--single {
    height: 78px;
    @media (max-width: 1700px) {
      height: 60px;
    }
  }
}
.select2-container--default .select2-selection--single {
  border-color: rgb($accent, 0.5);
  border-radius: 30px;
  background-color: transparent;
  @media (max-width: 1700px) {
    border-radius: 15px;
  }
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  color: $primary;
  line-height: 1.5em;
  @media (max-width: 1700px) {
    padding: 15px 20px;
  }
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: $secondary;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 26px;
  right: 15px;
  width: 20px;
  transform: scale(1.3);
  @media (max-width: 1700px) {
    top: 17px;
  }
}
.select2-dropdown {
  border-color: rgba($accent, 0.5);
  border-radius: 30px;
  @media (max-width: 1700px) {
    border-radius: 15px;
  }
}
.select2-search--dropdown {
  padding: 20px 20px 10px 20px;
}
.select2-results__option {
  padding: 7px 20px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: rgba($accent, 0.5);
  border-radius: 10px;
}
.select2-search--dropdown .select2-search__field {
  padding: 9px 15px;
  outline: none;
}
.select2-results__option {
  padding: 10px 20px;
  margin: 0 20px;
  border-radius: 10px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: rgba($accent, 0.15);
  color: $accent;
}
.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: $accent;
  color: white;
}
.select2-container--default .select2-results > .select2-results__options {
  padding-bottom: 10px;
  color: $primary;
}

/* End Select Two */
/* Start Scroll Up */
.cs_scrollup {
  position: fixed;
  bottom: -60px;
  right: 40px;
  color: $accent;
  padding: 5px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease;
  z-index: 10;
  background-color: $white;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  border-radius: 50%;
  font-size: 22px;
  &:hover {
    background-color: $primary;
    color: $white;
  }
  @media (max-width: 575px) {
    right: 20px;
  }
}

.cs_scrollup.cs_scrollup_show {
  bottom: 50px;
  @media (max-width: 575px) {
    bottom: 30px;
  }
}
/* End Scroll Up */
/* Start New Animations */
@keyframes particalAnimation {
  0%,
  100% {
    transform: translate(0px, 0px);
  }

  20% {
    transform: translate(80px, -10px);
  }

  40% {
    transform: translate(140px, 70px);
  }

  60% {
    transform: translate(90px, 130px);
  }

  80% {
    transform: translate(-40px, 80px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes animo-x {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(44px);
  }
}

@keyframes anim-y {
  50% {
    animation-timing-function: cubic-bezier(0.55, 0, 0.2, 1);
    transform: translateY(-25px);
  }
}

@keyframes rotate-anim {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes semi-rotate-anim {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(25px) rotate(-53deg);
  }
}

@keyframes up-down-anim {
  40% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(40px) rotate(-1deg);
  }
}

@keyframes swing-anim {
  65% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-48px) rotate(-75deg);
  }
}
@keyframes zoom-anim {
  50% {
    transform: scale(0.7);
    // opacity: 0.5;
  }
}
/* End New Animations */
.cursor {
  position: fixed;
  left: 0;
  pointer-events: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  color: $accent;
  background-color: #fff;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0;
  transition: 0.15s;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
}

.cs_cursor_lg,
.cs_cursor_sm {
  position: fixed;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  // mix-blend-mode: difference;
  z-index: 9999;
  @media (max-width: 991px) {
    display: none !important;
  }
}
.cs_cursor_lg {
  width: 40px;
  height: 40px;
  // border: 1px solid #ff4a17;
  background-color: rgba($accent, 0.15);
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.15s;
}
.cs_cursor_sm {
  width: 8px;
  height: 8px;
  background-color: $accent;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.2s;
}
.cs_section_heading.cs_style_1 {
  @media (max-width: 991px) {
    .cs_section_title {
      br {
        display: none;
      }
    }
  }
}
.cs_section_wrap_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 50px;
  @media (max-width: 991px) {
    .cs_avatar_card {
      margin-bottom: -60px;
    }
  }
}
.cs_avatar_card {
  .cs_avatar_card_title {
    margin-bottom: 24px;
  }
}
.cs_avatar_group {
  display: flex;
  padding-left: 30px;
  .cs_avatar_item {
    flex: none;
    height: 106px;
    width: 106px;
    border-radius: 50%;
    padding: 5px;
    margin-left: -30px;
    background-color: #fff;
    @media (max-width: 1400px) {
      height: 80px;
      width: 80px;
      padding: 4px;
    }
    @media (max-width: 991px) {
      height: 60px;
      width: 60px;
      padding: 1px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 420px) {
      display: none;
      &:nth-child(2),
      &:first-child,
      &:last-child {
        display: flex;
      }
    }
  }
}
